<template>
  <v-row>
    <v-dialog
      v-model="modalData.dialog"
      max-width="1850px"
      scrollable
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="permissionCan('create')"
          color="primary"
          dark
          class="ml-6 mb-2"
          v-bind="attrs"
          v-on="on"
          @click="handleNew"
        >
          New Sales order
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }}</span>
              <span v-html="soNumber"></span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#232341"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              v-if="
                computedSalesOrderStatuses &&
                computedSalesOrderStatuses.length > 0
              "
            >
              <v-tabs dark background-color="#232341" fixed-tabs>
                <v-tabs-slider color="#232341"></v-tabs-slider>
                <v-tab
                  v-for="(i, index) in computedSalesOrderStatuses"
                  :key="index"
                  :href="'#tab-' + index"
                  :disabled="index != calculateStatus(formModel.status)"
                >
                  <span style="color: #fff">{{ i }}</span>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height: 90%">
          <!-- <div id="scrollTo">SCROLLTOOOOOOOOOOOOO</div> -->
          <v-form v-model="formValid" ref="form">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.offerButton"
                  @click="handleStatusSubmit(2)"
                  >Make an offer</v-btn
                >
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.orderButton"
                  @click="
                    handleStatusSubmit(formModel.status == 1 ? 4 : 5, true)
                  "
                  >Order</v-btn
                >

                <v-btn
                  color="primary mr-2 mb-4"
                  elevation="2"
                  v-if="elements.contractButton"
                  @click="handleStatusSubmit(6)"
                  >Contract</v-btn
                >
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.deliveredButton"
                  @click="handleStatusSubmit(10)"
                  >Delivered</v-btn
                >
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.closeButton && formModel.paid && formModel.rating_notif !== null"
                  @click="handleStatusSubmit(11)"
                  >Close</v-btn
                >
              </v-col>

              <v-spacer></v-spacer>
              <v-col cols="12" sm="4" md="4">
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.detailedStatusButton"
                  @click="handleOpenDetailedStatus"
                  >Detailed status
                </v-btn>

                <v-btn
                  :salesOrderStatuses="salesOrderStatuses"
                  color="primary mr-2"
                  elevation="2"
                  v-if="
                    elements.versionHistoryButton && !newVersion && formModel.id
                  "
                  >Version history</v-btn
                >
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.activityButton && !newVersion && formModel.id"
                  @click="activityDialog = true"
                  >Activity
                </v-btn>
                <v-btn
                  v-if="formModel.id"
                  class="button"
                  @click="printSalesOrder"
                  color="primary mr-2"
                  elevation="2"
                  target="_blank"
                  >Print</v-btn
                >
                <v-btn
                  v-if="elements.offerPrintButton && formModel.id"
                  class="button"
                  @click="printOffer"
                  color="primary mr-2"
                  elevation="2"
                  target="_blank"
                  >Offer print</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="
                  formModel.scheduled_date || elements.scheduledDateSelector
                "
              >
                <label for="datepicker-full-width">Scheduled date</label
                ><b-form-datepicker
                  id="datepicker-scheduled_date"
                  v-model="formModel.scheduled_date"
                  :min="new Date(Date.now())"
                  start-weekday="1"
                  calendar-width="100%"
                  locale="en"
                  class="mb-2"
                  style="max-width: 260px"
                  required
                  :disabled="!elements.scheduledDateSelector"
                  @context="messages['scheduled_date'] = ''"
                ></b-form-datepicker>
                <small v-if="messages['scheduled_date']" style="color: red">
                  {{
                    messages["scheduled_date"].replace(" when status is 6", "")
                  }}
                </small>
              </v-col>
            </v-row>
            <v-row justify="center">
              <SendEmailModalForm
                :sendEmailDialog="sendEmailDialog"
                :salesOrderModel="formModel"
                @closeSendEmailDialog="handleCloseSendEmailDialog"
              >
              </SendEmailModalForm>
              <ActivityModal
                :activityDialog="activityDialog"
                :salesOrderModel="formModel"
                :salesOrderStatuses="salesOrderStatuses"
                @closeActivityModal="handleCloseActivityModal"
              >
              </ActivityModal>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.approveButton"
                  @click="handleStatusSubmit(formModel.status + 1)"
                  >Approve</v-btn
                >

                <b v-if="formModel.approved_at"
                  >Approved by: {{ formModel.approved_by }} </b
                ><span v-if="formModel.approved_at"
                  >({{ formModel.approved_at }})</span
                >
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.sendButton"
                  @click="sendEmailDialog = true"
                  >Send in email</v-btn
                >
                <span v-if="formModel.sent_at"
                  ><b v-if="formModel.sent_at"
                    >Sent by: {{ formModel.sent_by }}</b
                  >
                  ({{ formModel.sent_at }})</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6" v-if="elements.sendButton">
                Link: <a :href="SOLink" target="_blank">{{ SOLink }}</a>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3" md="3">
                <ul>
                  <li s>
                    <h4 class="text-lg-right">
                      Total:
                      {{
                        formModel.price_list_id ? $helpers.getNicePrice(
                          1 * totalNetValue + 1 * discountNetValue,
                          selectedPriceList.currency_id
                        ) : 0
                      }}
                      (Net)
                    </h4>
                  </li>
                  <li>
                    <h4 class="text-lg-right">
                      {{
                        formModel.price_list_id ? $helpers.getNicePrice(
                          1 * totalGrossValue + 1 * discountGrossValue,
                          selectedPriceList.currency_id
                        ) : 0
                      }}
                      (Gross)
                    </h4>
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="2" md="2">
                <v-autocomplete
                  v-model="formModel.sales_point_id"
                  :items="salesPoints"
                  item-text="name"
                  item-value="id"
                  label="Sales point"
                  :rules="requiredRules"
                  dense
                  chips
                  :disabled="!elements.salesPointSelector"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-autocomplete
                  v-model="formModel.owner_id"
                  :items="owners"
                  item-text="fullName"
                  item-value="id"
                  label="Sales person"
                  dense
                  chips
                  :rules="requiredRules"
                  :disabled="
                    !formModel.sales_point_id ||
                    !elements.assigneeSelector ||
                    (!permissionCan('assign.self') &&
                      !permissionCan('assign.person'))
                  "
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-btn
                  v-if="elements.assignButton"
                  color="primary mr-2"
                  elevation="2"
                  @click="handleStatusSubmit(formModel.status + 1)"
                  >assign</v-btn
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="4" md="4">
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.lostButton"
                  @click="handleLost(1)"
                  >Lost</v-btn
                >
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.unlostButton"
                  @click="handleLost(0)"
                  >Unlost</v-btn
                >
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.revokeButton"
                  @click="handleStatusSubmit(12)"
                  >Revoke</v-btn
                >
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.newVersionButton && !newVersion"
                  @click="handleNewVersion"
                  >New version</v-btn
                >
                <v-btn
                  color="primary mr-2"
                  v-if="
                    (permissionCan('create') && !this.editedItem) ||
                    permissionCan('update')
                  "
                  elevation="2"
                  @click="handleSaveModalForm"
                  :disabled="!elements.saveButton"
                >
                  Save
                </v-btn>
                <v-btn
                  color="primary mr-2"
                  elevation="2"
                  v-if="elements.deleteButton && permissionCan('delete')"
                  @click="handleDeleteSalesOrder"
                  >Delete</v-btn
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="2" md="2">
                <v-select
                  v-model="formModel.source_type_id"
                  :items="sourceTypeCollection"
                  item-text="name"
                  item-value="id"
                  label="Source type"
                  :disabled="!elements.sourceTypeSelector"
                  :id="dynamicID"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-select
                  v-model="formModel.locale"
                  :disabled="!elements.languageSelector && formModel.id"
                  :items="languages"
                  item-text="name"
                  item-value="lang"
                  label="Language"
                  :id="dynamicID"
                  :rules="languageRules"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-select
                  v-model="formModel.payment_method"
                  :items="paymentCollection"
                  label="Payment Method"
                  :item-text="(item) => item.value"
                  :item-value="(item) => item.key"
                  :return-object="false"
                  clearable
                  :disabled="formModel.paid"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-checkbox
                  v-model="formModel.paid"
                  class="mt-3"
                  color="primary"
                  label="Order paid"
                  hide-details
                  dense
                  :disabled="!elements.paidBox || !formModel.payment_method"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :rules="requiredCustomerRules"
                  v-model="formModel.customer"
                  :items="contactListCollection"
                  item-text="name"
                  label="Search customer"
                  return-object
                  :disabled="!elements.customerSelector"
                  @change="handleChangeCustomer"
                >
                </v-autocomplete>

                <table style="width: 100%" v-if="formModel.customer">
                  <tr>
                    <th>Company</th>
                    <th>Contact person</th>
                    <th>Phone no.</th>
                    <th>E-mail</th>
                    <th v-if="elements.customerSelector">Action</th>
                  </tr>
                  <tr>
                    <td style="cursor: pointer">
                      <span
                        v-if="formModel.customer"
                        @click="handleEditCompany(formModel.customer)"
                      >
                        {{ formModel.customer.companyName }}
                      </span>
                    </td>
                    <td
                      @click="editContactItem(formModel.customer.id)"
                      style="cursor: pointer"
                    >
                      <span v-if="formModel.customer">{{
                        formModel.customer.contactName
                      }}</span>
                    </td>
                    <td>
                      <span v-if="formModel.customer">{{
                        formModel.customer.phones
                      }}</span>
                    </td>
                    <td>
                      <span v-if="formModel.customer">{{
                        formModel.customer.email
                      }}</span>
                    </td>
                    <td v-if="elements.customerSelector">
                      <!-- <v-icon
                        small
                        @click="handleEditCompany(formModel.customer)"
                      >
                        mdi-pencil
                      </v-icon> -->
                      <v-icon
                        small
                        color="red"
                        @click="
                          formModel.customer = null;
                          customerCompany = { vat_number: '' };
                        "
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      dark
                      class="ml-4 mb-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleNewCompany"
                      v-if="elements.customerSelector"
                    >
                      New company
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      class="ml-4 mb-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleNewContact"
                      v-if="elements.customerSelector"
                    >
                      New contact
                    </v-btn>
                    <!-- @click="handleShowACactivityDialog()" -->
                    <v-btn
                      v-if="formModel.contact_id"
                      color="primary"
                      class="ml-4 mb-2"
                      elevation="2"
                      @click="handleOpenAC()"
                      >Contact Activity
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <span
                      class="ml-4"
                      v-if="
                        formModel.customer &&
                        formModel.customer.google_rating > 0
                      "
                    >
                      Rating:
                      <v-icon
                        v-for="rating in formModel.customer.google_rating"
                        :key="rating"
                        style="cursor: default"
                        color="primary"
                        small
                        right
                        locale="en-US"
                        aria-controls="example-input"
                      >
                        mdi-star
                      </v-icon>
                    </span>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-select
                      v-if="this.elements.ratingNotifSelector"
                      v-model="formModel.rating_notif"
                      :items="ratingNotifItems"
                      label="Rating notification"
                      :item-text="(item) => item.value"
                      :item-value="(item) => item.key"
                      :return-object="false"
                      :disabled="enableRatingNotif"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" md="2">
                <v-text-field
                  v-if="customerCompany"
                  v-model="customerCompany.vat_number"
                  label="Tax number"
                  :id="dynamicID"
                  disabled
                  :error-messages="messages['vat_number']"
                  @input="handleClearDeliveryError"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="formModel.addresses.billingAddress"
                  :items="billingAddresses"
                  item-text="name"
                  item-value="name"
                  label="Billing address"
                  dense
                  chips
                  return-object
                  :disabled="!elements.billingSelector"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="formModel.addresses.shippingAddress"
                  :items="shippingAddresses"
                  item-text="name"
                  item-value="name"
                  label="Shipping address"
                  dense
                  chips
                  return-object
                  :disabled="!elements.deliverySelector"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="2" md="2">
                <label for="datepicker-full-width">Expiration date</label>
                <b-form-datepicker
                  id="datepicker-expiration_date"
                  v-model="formModel.expiration_date"
                  :min="new Date(Date.now())"
                  start-weekday="1"
                  calendar-width="100%"
                  locale="en"
                  class="mb-2"
                  :disabled="!elements.expirationSelector"
                ></b-form-datepicker>
                <small
                  v-if="
                    messages['expiration_date'] &&
                    messages['expiration_date'] != today
                  "
                  style="color: red"
                >
                  {{ messages["expiration_date"] }}
                </small>
                <div v-if="elements.expirationSelector">
                  <a @click.prevent="addDays(15)">+15 days</a>
                  <br />
                  <a @click.prevent="addDays(30)">+30 days</a>
                </div>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <label for="datepicker-full-width">Exp. delivery date</label>
                <b-input-group>
                  <b-form-datepicker
                    id="datepicker-delivery_date"
                    v-model="formModel.delivery_date"
                    :min="formModel.expiration_date"
                    start-weekday="1"
                    locale="en"
                    calendar-width="100%"
                    class="mb-2"
                    :disabled="
                      formModel.delivery_days > 0 ||
                      !elements.expDeliverySelector
                    "
                    :error-messages="messages['delivery_date']"
                    @input="handleClearDeliveryError"
                  >
                  </b-form-datepicker>
                  <b-input-group-append
                    :disabled="
                      formModel.delivery_date &&
                      (formModel.delivery_days > 0 ||
                        !elements.expDeliverySelector)
                    "
                  >
                    <!-- v-if="
                        formModel.delivery_date &&
                        (formModel.delivery_days > 0 ||
                          !elements.expDeliverySelector)
                      " -->
                    <v-icon
                      small
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      @click="formModel.delivery_date = null"
                    >
                      mdi-delete
                    </v-icon>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group>
                  <v-text-field
                    v-model="formModel.delivery_days"
                    type="number"
                    min="0"
                    label="Days from order"
                    :id="dynamicID"
                    :disabled="
                      formModel.delivery_date || !elements.expDeliverySelector
                    "
                    :error-messages="messages['delivery_days']"
                    @input="handleClearDeliveryError"
                  ></v-text-field>
                  <b-input-group-append>
                    <!-- v-if="
                    formModel.delivery_days &&
                    (formModel.delivery_days > 0 ||
                      !elements.expDeliverySelector)
                  " -->
                    <v-icon
                      v-if="
                        formModel.delivery_days >= 0 ||
                        !elements.expDeliverySelector
                      "
                      small
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      @click="formModel.delivery_days = null"
                    >
                      mdi-delete
                    </v-icon>
                  </b-input-group-append>
                </b-input-group>
                <!-- <v-icon
                  v-if="
                    formModel.delivery_days &&
                    (formModel.delivery_days > 0 ||
                      !elements.expDeliverySelector)
                  "
                  small
                  @click="formModel.delivery_days = null"
                >
                  mdi-delete
                </v-icon> -->
              </v-col>
            </v-row>
            <v-row>
              <!-- <pre>
                {{ priceListCollection }}
              </pre> -->
              <v-col cols="12" sm="2" md="2">
                <v-select
                  v-model="formModel.price_list_id"
                  :items="priceListCollection"
                  item-text="name"
                  item-value="id"
                  label="Price list"
                  :rules="requiredRules"
                  :id="dynamicID"
                  :disabled="
                    formModel.items.length > 0 ||
                    (formModel.id && !elements.priceListSelector)
                  "
                  @change="handleChangeSelectedPriceList"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <!-- @change="handleChangeSelectedSpecialPriceList" -->
                <v-select
                  v-model="formModel.special_price_list_id"
                  :items="[
                    { id: null, name: 'Select' },
                    ,
                    { id: 0, name: 'None' },
                    ...priceListCollection,
                  ]"
                  item-text="name"
                  item-value="id"
                  label="Special price list"
                  :rules="requiredRulesSpecialPriceList"
                  :id="dynamicID"
                  :disabled="
                    formModel.items.length > 0 ||
                    (formModel.id && !elements.priceListSelector)
                  "
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2" md="2">
                <v-checkbox
                  v-model="formModel.zero_tax"
                  color="primary"
                  label="0% Tax"
                  hide-details
                  :disabled="
                    !elements.paidBox ||
                    !elements.itemSelector == 1 ||
                    formModel.reverse_tax
                  "
                  @change="handleZeroTaxChange"
                ></v-checkbox>
              </v-col>


                <v-col cols="12" sm="2" md="2">
                <v-checkbox
                  v-model="formModel.expanded_offer"
                  color="primary"
                  label="Extended offer"
                ></v-checkbox>
              </v-col>
              <!-- <v-col cols="12" sm="2" md="2">
                <v-checkbox
                  v-model="formModel.reverse_tax"
                  color="primary"
                  label="Reverse Tax"
                  hide-details
                  :disabled="
                    !elements.paidBox ||
                    !elements.itemSelector == 1 ||
                    formModel.zero_tax
                  "
                  @change="handleZeroTaxChange"
                ></v-checkbox>
              </v-col> -->
            </v-row>

            <v-divider></v-divider>
            <div ref="scrollTo"></div>
            <v-form
              v-model="formItemDataValid"
              ref="formItemData"
              v-if="elements.itemSelector"
            >
              <v-row>
                <v-col cols="12" sm="2" md="2">
                  <v-autocomplete
                    v-if="!isEditedItemData"
                    v-model="itemInputData.item"
                    :items="filteredSalesOrderItemCollection"
                    item-text="ReferenceName"
                    item-key="price_list_item_id"
                    label="Search item"
                    return-object
                    :rules="requiredItemRules"
                    :error-messages="messages['items']"
                    @change="handleChangeItemInputDataItem"
                    :disabled="formModel.special_price_list_id == null"
                  >
                  </v-autocomplete>
                  <h6 v-else class="mt-5">
                    {{ itemInputData.reference }} | {{ itemInputData.name }}
                  </h6>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="itemInputData.num_of_pcs"
                    label="Num of pcs"
                    type="number"
                    min="1"
                    :rules="minRules"
                    v-on:input="calculatePriceGross"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <div class="d-flex flex-row">
                    <div>
                      <v-select
                        v-if="itemInputData.item"
                        v-model="itemInputData.quantity"
                        label="Quantity"
                        :items="computedItemQuantitiesArray"
                        :rules="requiredRules"
                        :id="dynamicID"
                        @change="handleQuantityChange"
                      />
                    </div>

                    <div class="pa-1 mt-4" style="white-space: nowrap">
                      {{ itemInputDataQuantityUnit }}
                    </div>
                  </div>
                </v-col>
                <!-- <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-if="itemInputDataQuantityUnit"
                    v-model="itemInputDataQuantityUnit"
                    label="Quantity unit"
                    disabled
                    :id="dynamicID"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="itemInputData.price_net"
                    label="Net unit price"
                    type="number"
                    :rules="minRules"
                    v-on:input="calculatePriceGross"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="itemInputData.vat_name"
                    label="Tax name"
                    disabled
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="itemInputData.vat_value"
                    label="Tax rate"
                    type="number"
                    disabled
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="itemInputData.price_gross"
                    label="Gross unit price"
                    :rules="minRules"
                    v-on:input="handleCalculateNetto"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="itemInputData.netValue"
                    label="Net value"
                    disabled
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="itemInputData.tax"
                    label="tax"
                    type="number"
                    disabled
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="itemInputData.grossValue"
                    label="Gross value"
                    type="number"
                    disabled
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1">
                  <v-text-field
                    v-model="selectedPriceList.currency_id"
                    label="Currency"
                    disabled
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemInputData.alt_name"
                    label="SO item name"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    v-model="itemInputData.public_notes"
                    label="Comment"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="2" md="2">
                  <v-btn
                    color="success"
                    @click="handleAddItemData"
                    class="ma-2"
                  >
                    Add
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    @click="handleCancelItemData"
                    :disabled="!cancelItemData"
                  >
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <!-- <pre>
              {{ calculatedItems }}
            </pre> -->
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  v-if="newVersion || (calculatedItems && !formModel.id)"
                  :headers="headers"
                  :items="calculatedItems"
                  :search="search"
                  v-sortable-data-table
                  @sorted="saveOrder"
                  item-key="item_id"
                >
                  <template v-slot:item="{ item }">
                    <tr :class="{ fixed: formModel.id && !newVersion }">
                      <td>
                        <b>
                          {{ item.reference }}
                        </b>
                      </td>
                      <td>
                        <b v-if="item.alt_name || item.public_notes">
                          Item name:
                        </b>
                        {{ item.name }}
                        <span v-if="item.unit_weight">
                          <br />
                          Weight:
                          {{
                            Math.round(
                              item.quantity *
                                item.num_of_pcs *
                                item.unit_weight *
                                1000
                            ) / 1000
                          }}
                          Kg
                        </span>
                        <div v-if="item.alt_name" class="mt-1">
                          <b>SO item name:</b> {{ item.alt_name }}
                        </div>
                        <div v-if="item.public_notes" class="mt-2">
                          <b>Comment:</b> {{ item.public_notes }}
                        </div>
                      </td>
                      <td>{{ item.num_of_pcs }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.quantityUnit }}</td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            item.price_net,
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>{{ item.vat_name }}</td>
                      <td>
                        <span v-if="formModel.zero_tax"></span>
                        <span v-else-if="item">
                          {{

                            item.vat_value,

                          }}
                        </span>
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            item.price_gross,
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            item.netValue,
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>
                        <span v-if="formModel.zero_tax"></span>
                        <span v-else-if="item">
                          {{
                            $helpers.getNicePrice(
                              item.tax,
                              selectedPriceList.currency_id
                            )
                          }}
                        </span>
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            item.grossValue,
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <!-- <td>{{ selectedPriceList.currency_id }}</td> -->
                      <td>
                        <span v-if="formModel.status != 0">
                          <v-icon
                            small
                            class="mr-2"
                            @click="handleEditItem(item)"
                            :disabled="isEditedItemData"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon small @click="handleDeleteItem(item)">
                            mdi-delete
                          </v-icon>
                        </span>
                      </td>
                    </tr>
                  </template>
                  <!-- <tr v-if="item.alt_name || item.public_notes">
                    <td></td>
                    <td>{{ item.alt_name }}</td>
                    <td colspan="10">
                      {{ item.public_notes }}
                    </td>
                  </tr> -->

                  <template v-slot:body.append>
                    <tr style="font-weight: bold" class="fixed">
                      <td></td>
                      <td>
                        <span v-if="totalWeight">
                          Total weight: {{ totalWeight }} kg
                        </span>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total:</td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            getFormattedPrice(+totalNetValue),
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            getFormattedPrice(+totalTax),
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            getFormattedPrice(+totalGrossValue),
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <!-- <td>
                        {{ selectedPriceList.currency_id }}
                      </td> -->
                      <td></td>
                    </tr>
                    <tr style="font-weight: bold" class="fixed">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <v-select
                          v-model="formModel.discount_type"
                          :items="[
                            { key: null, value: 'Type' },
                            ...discountCollection,
                          ]"
                          item-text="value"
                          item-value="key"
                          label="Discount unit"
                          :disabled="!elements.discountSelector"
                          :id="dynamicID"
                          :error-messages="messages['discount_type']"
                          
                          @change="messages['discount_type'] = ''"
                        >
                        </v-select>
                      </td>
                      <td>
                        <v-text-field
                          v-model="formModel.discount_value"
                          type="number"
                          min="0"
                          label="Discount value"
                          :disabled="!elements.discountSelector"
                          :id="dynamicID"
                          :error-messages="messages['discount_value']"
                          @input="messages['discount_value'] = ''"
                        ></v-text-field>
                      </td>
                      <td>{{ getFormattedPrice(discountNetValue) }}</td>
                      <td>{{ discountTax }}</td>
                      <td>{{ discountGrossValue }}</td>
                      <!-- <td>{{ selectedPriceList.currency_id }}</td> -->
                      <td></td>
                    </tr>

                    <tr style="font-weight: bold" class="fixed">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <h5>Total amount payable:</h5>
                      </td>
                      <td style="white-space: nowrap">
                        <h5>
                          {{
                            $helpers.getNicePrice(
                              getFormattedPrice(
                                1 * totalNetValue + 1 * discountNetValue
                              ),
                              selectedPriceList.currency_id
                            )
                          }}
                        </h5>
                      </td>
                      <td></td>
                      <td style="white-space: nowrap">
                        <h5>
                          {{
                            $helpers.getNicePrice(
                              getFormattedPrice(
                                1 * totalGrossValue + 1 * discountGrossValue
                              ),
                              selectedPriceList.currency_id
                            )
                          }}
                        </h5>
                      </td>
                      <!-- <td>
                        <h5>
                          {{ selectedPriceList.currency_id }}
                        </h5>
                      </td> -->
                      <td></td>
                    </tr>
                  </template>
                </v-data-table>
                <!-- show-expand -->

                <v-data-table
                  key="unsorted"
                  v-else-if="calculatedItems"
                  :headers="headers"
                  :items="calculatedItems"
                  :search="search"
                  item-key="item_id"
                  :sort-by="['sales_order_item_id']"
                >
                  <template v-slot:item="{ item }">
                    <tr @click="handleOpenSOEntitySelectDialog(item)">
                      <td>
                        <b>
                          {{ item.reference }}
                        </b>
                      </td>
                      <td>
                        <b v-if="item.alt_name || item.public_notes">
                          Item name:
                        </b>
                        {{ item.name }}
                        <span v-if="item.unit_weight">
                          <br />
                          Weight:
                          {{
                            Math.round(
                              item.quantity *
                                item.num_of_pcs *
                                item.unit_weight *
                                1000
                            ) / 1000
                          }}
                          Kg
                        </span>
                        <div v-if="item.alt_name" class="mt-1">
                          <b>SO item name:</b> {{ item.alt_name }}
                        </div>
                        <div v-if="item.public_notes" class="mt-2">
                          <b>Comment:</b> {{ item.public_notes }}
                        </div>
                      </td>
                      <td>{{ item.num_of_pcs }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.quantityUnit }}</td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            +item.price_net,
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>{{ item.vat_name }}</td>
                      <td>
                        <span v-if="formModel.zero_tax"></span>
                        <span v-else-if="item">{{ item.vat_value }}</span>
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            +item.price_gross,
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            +item.netValue,
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td style="white-space: nowrap">
                        <span v-if="formModel.zero_tax"></span>
                        <span v-else-if="item">
                          {{
                            $helpers.getNicePrice(
                              +item.tax,
                              selectedPriceList.currency_id
                            )
                          }}
                        </span>
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            +item.grossValue,
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>
                        <span
                          v-if="formModel.status != 0 && elements.itemSelector"
                        >
                          <v-icon
                            small
                            class="mr-2"
                            @click="handleEditItem(item)"
                            :disabled="isEditedItemData"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon small @click="handleDeleteItem(item)">
                            mdi-delete
                          </v-icon>
                        </span>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:body.append>
                    <tr style="font-weight: bold" class="fixed">
                      <td></td>
                      <td>
                        <span v-if="totalWeight">
                          Total weight: {{ totalWeight }} kg
                        </span>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Total:</td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            getFormattedPrice(totalNetValue),
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            getFormattedPrice(totalTax),
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td>
                        {{
                          $helpers.getNicePrice(
                            getFormattedPrice(totalGrossValue),
                            selectedPriceList.currency_id
                          )
                        }}
                      </td>
                      <td></td>
                    </tr>
                    <tr style="font-weight: bold" class="fixed">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style="max-width: 150px">
                        <v-select
                          v-model="formModel.discount_type"
                          :items="[
                            { key: null, value: 'Type' },
                            ...discountCollection,
                          ]"
                          item-text="value"
                          item-value="key"
                          label="Discount unit"
                          :disabled="!elements.discountSelector"
                          :id="dynamicID"
                          :error-messages="messages['discount_type']"
                          @change="messages['discount_type'] = ''"
                        >
                        </v-select>
                      </td>
                      <td>
                        <v-text-field
                          v-model="formModel.discount_value"
                          type="number"
                          min="0"
                          label="Discount value"
                          :disabled="!elements.discountSelector"
                          :id="dynamicID"
                          :error-messages="messages['discount_value']"
                          @input="messages['discount_value'] = ''"
                        ></v-text-field>
                      </td>
                      <td>{{ getFormattedPrice(discountNetValue) }}</td>
                      <td>{{ discountTax }}</td>
                      <td>{{ discountGrossValue }}</td>
                      <td></td>
                    </tr>

                    <tr style="font-weight: bold" class="fixed">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <h5>Total amount payable:</h5>
                      </td>
                      <td style="white-space: nowrap">
                        <h5>
                          {{
                            $helpers.getNicePrice(
                              getFormattedPrice(
                                1 * totalNetValue + 1 * discountNetValue
                              ),
                              selectedPriceList.currency_id
                            )
                          }}
                        </h5>
                      </td>
                      <td></td>
                      <td style="white-space: nowrap">
                        <h5>
                          {{
                            $helpers.getNicePrice(
                              getFormattedPrice(
                                1 * totalGrossValue + 1 * discountGrossValue
                              ),
                              selectedPriceList.currency_id
                            )
                          }}
                        </h5>
                      </td>
                      <td></td>
                    </tr>
                  </template>

                  <!-- <template v-slot:expanded-item="{ item }">
                    <tr style="font-weight: bold" class="fixed">
                      <td></td>
                      <td></td>
                      <td colspan="8">
                        {{ item.alt_name }}
                        <br />
                        {{ item.public_notes }}
                      </td>
                    </tr>
                  </template> -->
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="formModel.notes"
                  label="Internal comment"
                  rows="10"
                  :error-messages="messages['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="formModel.public_notes"
                  label="Comment to customer"
                  rows="10"
                  :error-messages="messages['public_notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <!-- <pre>
            {{ formModel.items }}
          </pre> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <v-btn
            v-if="
              (permissionCan('create') && !this.editedItem) ||
              permissionCan('update')
            "
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
            :disabled="!elements.saveButton"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="dialogDeleteItem" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteItem">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackBarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogDeleteCompany"
      max-width="650px"
      v-if="company2Delete"
    >
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete company {{ company2Delete.name }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteCompanyConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CompanyModalForm
      :modalData="companyModalData"
      :permissions="companyPermissions"
      :industryCollection="industryCollection"
      :qualificationCollection="qualificationCollection"
      :companyTypeCollection="companyTypeCollection"
      :companyCategoryCollection="companyCategoryCollection"
      :countryCollection="countryCollection"
      :supplierTypeCollection="supplierTypeCollection"
      :statuses="statuses"
      :languages="languages"
      :defaultTab="0"
      @closeModalForm="handleCloseCompanyModalForm"
      @saveModalForm="handleSaveCompanyModalForm"
      @handleDeleteCompany="handleDeleteCompany"
    ></CompanyModalForm>
    <ContactModalForm
      :permissions="companyPermissions"
      :modalData="modalDataContact"
      :companyCollection="companyCollection"
      @saveContactModal="handleSaveContactModal"
      @closeModal="handleCloseContactModal"
    >
    </ContactModalForm>
    <SOEntityDialog
      :salesOrderItemId="salesOrderItemId"
      @handleCloseSOEntityDialog="handleCloseSOEntityDialog"
    >
    </SOEntityDialog>
    <SODetailedDialog
      :dialog="SODetailedStatusdialog"
      :salesOrder="formModel"
      @handleCloseSODetailedDialog="handleCloseSODetailedDialog"
    >
    </SODetailedDialog>
    <ACActivityDialog
      :dialogData="ACDialogData"
      @handleCloseDialog="handleCloseACactivityDialog"
    ></ACActivityDialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ENDPOINT } from "./SalesOrders";
import SendEmailModalForm from "./SendEmailModalForm";
import ACActivityDialog from "@/view/components/ACActivityDialog";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import ActivityModal from "./ActivityModal.vue";
import CompanyModalForm, {
  initialCompanyFormData,
  // } from "./CompanyModalForm";
} from "@/view/pages/contacts/contactList/CompanyModalForm";

import ContactModalForm from "@/view/pages/contacts/contactList/ContactModalForm.vue";

import SOEntityDialog from "@/view/components/SOEntityDialog.vue";
import SODetailedDialog from "@/view/components/SODetailedDialog.vue";

import Swal from "sweetalert2";
import Sortable from "sortablejs";

const TRANSLATED_ATRIBUTES = [];

const now = new Date();
const today =
  now.getFullYear() + "-" + 1 * (now.getMonth() + 1) + "-" + now.getDate();

export const initialFormData = () => ({
  id: null,
  old_status: null,
  prev_status: null,
  translations: {},
  dialog: false,
  expiration_days: null,
  expiration_date: today,
  delivery_date: null,
  delivery_days: "",
  company_id: null,
  contact_id: null,
  owner_id: null,
  source_type_id: null,
  sales_point_id: null,
  salesPoint: null,
  paid: false,
  newVersion: false,
  zero_tax: 0,
  addresses: {
    billingAddress: {},
    shippingAddress: {},
  },
  locale: null,
  notes: "",
  discount_type: null,
  discount_value: null,
  price_list_id: null,
  special_price_list_id: null,
  status: 1,
  companyAddresses: [],
  items: [],
  customer: null,
  scheduled_date: null,
  payment_method: null,
  public_notes: null,
  rating_notif: null,
  expanded_offer: 0,
});

const initialItemInputData = {
  id: null,
  item: {},
  name: "",
  reference: null,
  currency_id: null,
  price_net: null,
  price_gross: null,
  netValue: null,
  grossValue: null,

  vat_name: null,
  vat_value: null,
  tax: null,

  quantity: 0,
  quantityUnit: null,
  num_of_pcs: 1,
  alt_name: null,
  public_notes: null,
};

const initialElements = () => ({
  activityButton: 1,
  approveButton: 0,
  assigneeSelector: 1,
  billingSelector: 1,
  closeButton: 0,
  commentInput: 1,
  contractButton: 0,
  customerSelector: 1,
  deleteButton: 1,
  deliverySelector: 1,
  discountSelector: 1,
  expDeliverySelector: 1,
  expirationSelector: 1,
  itemSelector: 1,
  lostButton: 0,
  newVersionButton: 0,
  offerButton: 1,
  orderButton: 1,
  paidBox: 1,
  priceListSelector: 1,
  revokeButton: 0,
  salesPointSelector: 1,
  saveButton: 1,
  sendButton: 0,
  sourceTypeSelector: 1,
  versionHistoryButton: 1,
});

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default {
  name: "SalesOrdersForm",
  components: {
    SendEmailModalForm,
    ActivityModal,
    CompanyModalForm,
    ContactModalForm,
    SOEntityDialog,
    SODetailedDialog,
    ACActivityDialog,
  },
  props: [
    "modalData",
    "permissions",
    "editedItemId",
    "userCollection",
    "salesPointCollection",
    "contactListCollection",
    "sourceTypeCollection",
    "priceListCollection",
    "itemCollection",
    "quantityUnitCollection",
    "salesOrderStatuses",
  ],

  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages[0],
      formModel: initialFormData(),
      formValid: false,
      owners: [],
      messages: [],
      customerCompany: { vat_number: "" },
      billingAddresses: [],
      shippingAddresses: [],
      snackBarText: "Sales order successfully saved!",
      snackbar: false,
      newVersion: false,
      companyPermissions: [],
      modalDataContact: {
        dialog: false,
        editedId: null,
      },
      nameRules: [
        (v) => !!v || "Quantity unit name is required",
        (v) => v.length < 15 || "Quantity unit name must be max 16 characters",
      ],
      requiredRules: [(v) => !!v || "Field is required"],

      requiredRatingRules: [
        (v) => {
          if (this.formModel.status == 6) {
            if (v === 0 || v === 1) return true;
            return "Field is required";
          }
          return true;
        },
      ],
      // requiredRulesSpecialPriceList: [
      //   (v) => v !== null || this.formModel.id !== null || "Field is required",
      // ],

      requiredRulesSpecialPriceList: [
        (value) => {
          /*if (value === 0 && this.formModel.id) return true;
          if (value !== null && !this.formModel.id) return true;*/
          if (value !== null) return true;

          return "Field is required";
        },
      ],

      minRules: [
        (v) => {
          if (!v || v < 1) return "Field is required, min value is 1";
          return true;
        },
      ],
      requiredItemRules: [
        (v) => {
          if (!v || !v.name) return "Field is required";
          return true;
        },
      ],

      requiredCustomerRules: [
        (v) => {
          if (this.formModel.status != 0 && (!v || !v.name))
            return "Field is required";
          return true;
        },
      ],

      languageRules: [(v) => !!v || "Language is required"],
      discountCollection:
        window.localStorage.getItem("types") &&
        JSON.parse(window.localStorage.getItem("types")).sales
          ? JSON.parse(window.localStorage.getItem("types")).sales.discount
          : [],

      paymentCollection:
        window.localStorage.getItem("types") &&
        JSON.parse(window.localStorage.getItem("types")).sales
          ? JSON.parse(window.localStorage.getItem("types")).sales.payment.map(
              (item) => {
                item.value = this.$t("PAYMENT." + item.value);
                return item;
              }
            )
          : [],
      headers: [
        {
          text: "Reference",
          value: "reference",
        },
        {
          text: "Item name",
          value: "name",
          width: "300px",
        },
        {
          text: "Num of pcs",
          value: "num_of_pcs",
        },
        {
          text: "Quantity",
          value: "quantity",
        },
        {
          text: "Quantity unit",
          value: "quantityUnit",
        },
        {
          text: "Net unit price",
          value: "price_net",
          filterable: false,
        },
        {
          text: "Tax name",
          value: "vat_name",
          filterable: false,
        },
        {
          text: "Tax rate",
          value: "vat_value",
          filterable: false,
          width: "140px",
        },
        {
          text: "Gross unit price",
          value: "price_gross",
          filterable: false,
          width: "160px",
        },
        {
          text: "Net value",
          value: "netValue",
          filterable: false,
        },
        {
          text: "Tax",
          value: "tax",
          filterable: false,
        },
        {
          text: "Gross value",
          value: "grossValue",
          filterable: false,
        },
        // {
        //   text: "Currency",
        //   value: "currency",
        // },
        {
          text: "Actions",
          value: "actions",
          filterable: false,
        },
      ],
      companyModalData: {
        dialog: false,
        editedIndex: null,
        editedItem: Object.assign({}, initialCompanyFormData()),
      },
      // selectedPriceList: { currency_id: "" },
      selectedPriceList: {
        currency_id: null,
      },

      selectedSpecialPriceList: {
        currency_id: null,
      },
      salesOrderItemCollection: [],
      itemInputData: Object.assign({}, initialItemInputData),
      itemTodelete: null,
      dialogDeleteItem: false,
      formItemDataValid: false,
      isEditedItemData: false,
      cancelItemData: null,
      calculatedItems: [],
      elements: initialElements(),
      activityDialog: false,
      sendEmailDialog: false,
      companyDialog: false,
      today: today,
      showSortedItems: false,
      salesOrderItemId: null,
      SODetailedStatusdialog: false,
      company2Delete: null,
      dialogDeleteCompany: false,

      ACDialogData: {
        show: false,
        contactID: null,
      },

      ratingNotifItems: [
        { key: 0, value: "Don't request a rating" },
        { key: 1, value: "Request a rating" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "companyCollection",
      "contactListCollection",
      "statuses",
      "companyTypeCollection",
      "industryCollection",
      "qualificationCollection",
      "companyCategoryCollection",
      "countryCollection",
      "supplierTypeCollection",
      "currentUser",
    ]),

    // salesOrderStatuses() {
    //   return window.localStorage.getItem("salesOrderStatuses")
    //     ? JSON.parse(window.localStorage.getItem("salesOrderStatuses"))
    //     : [];
    // },
    SOLink() {
      return process.env.VUE_APP_PORTAL + `index.php?so=${this.formModel.hash}`;
    },

    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    formTitle() {
      if (this.newVersion) return "New version of ";
      // return this.editedItemId && !this.newVersion
      return this.formModel.id && !this.newVersion
        ? "Edit sales order"
        : "New sales order";
    },

    salesPoints() {
      let currentUserUserGroups = [];
      if (this.currentUser.user_groups) {
        currentUserUserGroups = this.currentUser.user_groups.map((item) => {
          return item.id;
        });
      }

      return this.salesPointCollection.filter((item) => {
        return currentUserUserGroups.includes(item.userGroup.id);
      });
    },

    totalNetValue() {
      let sum = 0;
      this.formModel.items.forEach((item) => {
        if (item) sum += +this.getFormattedPrice(1 * item.netValue);
      });
      return sum;
    },

    // totalTax() {
    //   if (this.formModel.zero_tax) {
    //     return 0;
    //   }
    //   let sum = 0;
    //   this.formModel.items.forEach((item) => {
    //     sum += 1 * item.tax;
    //   });
    //   return sum;
    // },
    totalTax() {
      if (this.formModel.zero_tax) {
        return 0;
      }
      let sum = 0;
      this.formModel.items.forEach((item) => {
        if (
          !(this.formModel.reverse_tax && item.item && item.item.service == 0)
        ) {
          sum += +this.getFormattedPrice(1 * item.tax);
        }
      });
      return sum;
    },

    totalGrossValue() {
      let sum = 0;
      if (this.formModel.zero_tax) {
        this.formModel.items.forEach((item) => {
          sum += +this.getFormattedPrice(1 * item.netValue);
        });
        return sum;
      }

      this.formModel.items.forEach((item) => {
        if (
          !(this.formModel.reverse_tax && item.item && item.item.service == 0)
        ) {
          sum += +this.getFormattedPrice(1 * item.grossValue);
        } else {
          sum += +this.getFormattedPrice(1 * item.netValue);
        }
      });
      return sum;
    },

    discountNetValue() {
      if (this.formModel.discount_type == 1) {
        return -(this.totalNetValue / 100) * this.formModel.discount_value;
      }
      // if (this.formModel.discount_type == 2) {
      //   return this.getFormattedPrice(
      //     (this.discountGrossValue / this.totalGrossValue) * this.totalNetValue,
      //     this.selectedPriceList.currency_id
      //   );
      // }

      if (
        this.selectedPriceList &&
        // this.totalGrossValue > 0 &&
        this.formModel.discount_type == 2 &&
        this.formModel.discount_value > 0
      ) {
        return -this.formModel.discount_value;
      }

      return "";
    },

    discountTax() {
      if (
        this.totalTax > 0 &&
        this.formModel.discount_type == 1 &&
        this.formModel.discount_value > 0
      ) {
        let pr = -(this.totalTax / 100) * this.formModel.discount_value;
        // return this.getFormattedPrice(pr, this.selectedPriceList.currency_id);
        return this.getFormattedPrice(pr);
      }
      if (
        this.totalTax > 0 &&
        this.formModel.discount_type == 2 &&
        this.formModel.discount_value > 0
      ) {
        return this.getFormattedPrice(
          -(this.formModel.discount_value / this.totalNetValue) * this.totalTax
        );
      }
      return "";
    },

    discountGrossValue() {
      if (
        this.selectedPriceList &&
        this.totalGrossValue > 0 &&
        this.formModel.discount_type == 1 &&
        this.formModel.discount_value > 0
      ) {
        return this.getFormattedPrice(
          -(this.totalGrossValue / 100) * this.formModel.discount_value
        );
      }
      // if (
      //   this.selectedPriceList &&
      //   this.totalGrossValue > 0 &&
      //   this.formModel.discount_type == 2 &&
      //   this.formModel.discount_value > 0
      // ) {
      //   return -this.formModel.discount_value;
      // }

      if (this.formModel.discount_type == 2) {
        return this.getFormattedPrice(
          // this.discountNetValue / this.formModel.discount_value) *
          // (this.discountValue / this.totalGrossValue) * this.totalTax,
          -(this.formModel.discount_value / this.totalNetValue) *
            this.totalGrossValue
        );
      }
      return "";
    },

    itemInputDataQuantityUnit() {
      if (this.itemInputData.item) {
        return this.getQuantityUnitById(
          this.itemInputData.item.quantity_unit_id
        );
      }
      return "";
    },

    computedSalesOrderStatuses() {
      if (!this.salesOrderStatuses) return [];
      let sts = this.salesOrderStatuses.filter((value, index) => {
        return index < 8 || index > 9;
      });
      sts.pop();
      return sts;
    },

    soNumber() {
      if (!this.formModel || !this.formModel.id) return "";

      let type = this.formModel.lost == 1 ? " - LOST" : " - REVOKED";
      if (this.formModel.lost == 1 || this.formModel.status == 12) {
        return "<del> - " + this.formModel.so_number + "</del>" + type;
      }

      return " " + this.formModel.so_number;
    },

    filteredSalesOrderItemCollection() {
      // let selectedItemIds = this.formModel.items.map((i) => i.id);
      // return this.salesOrderItemCollection.filter((item) => {
      //   return !selectedItemIds.includes(item.id);
      // });
      return this.salesOrderItemCollection;
    },

    computedItemQuantitiesArray() {
      if (!this.itemInputData.item || !this.selectedPriceList.items) return [];
      let addedItemIds = this.formModel.items
        .filter((item) => {
          return item.item_id == this.itemInputData.item.item_id;
          // return item.item_id == this.itemInputData.item.id;
          // return item.item_id == this.itemInputData.item_id;
        })
        .map((item) => {
          return +item.quantity;
        });

      let selectedPriceListItemQuantity = this.selectedPriceList.items
        .filter((item) => {
          return (
            item.item_id == this.itemInputData.item.item_id &&
            // item.item_id == this.itemInputData.item.id &&
            // item.item_id == this.itemInputData.item_id &&
            !addedItemIds.includes(+item.quantity)
          );
        })
        .map((item) => {
          return +item.quantity;
        });

      return selectedPriceListItemQuantity;
    },

    totalWeight() {
      if (this.calculatedItems) {
        let totalWeight = this.calculatedItems.reduce((accumulator, object) => {
          return (
            accumulator +
            object.quantity * object.num_of_pcs * object.unit_weight
          );
        }, 0);
        return Math.round(totalWeight * 1000) / 1000;
      }
      return null;
    },

    enableRatingNotif() {
      if (
        !this.formModel.customer ||
        this.formModel.customer.google_rating > 0 ||
        this.formModel.status == 11
      ) {
        return true;
      }

      return false;
    },
  },
  watch: {
    editedItemId(value) {
      this.salesOrderItemId = null;
      if (value) {
        this.loader = true;
        this.isEditedItemData = false;
        this.cancelItemData = null;
        this.itemInputData = Object.assign({}, initialItemInputData);
        if (this.contactListCollection.length == 0) {
          this.fetchContactList().then(() => {
            this.loadFormData(value);
          });
        } else {
          this.loadFormData(value);
        }
      } else {
        this.itemInputData = Object.assign({}, initialItemInputData);
        this.formModel = initialFormData();
        this.newVersion = false;
        if (!this.formModel.locale) {
          this.formModel.locale = i18nService.getActiveLanguage();
        }
        this.formModel.prev_status = this.formModel.status;
        this.elements = initialElements();
        this.calculateCalculatedItems(this.formModel.items);
        this.setTranslatedAttributes();
        this.resetErrorMessages();
      }
    },

    "formModel.customer.companyAddresses": {
      handler: function (val) {
        if (val) {
          let value = val;
          if (value.billing && value.billing.length > 0) {
            this.billingAddresses = value.billing;
            this.billingAddresses.forEach((item) => {
              let county = item.county ? item.county + ", " : "";
              item.name = `${item.country}, ${county} ${item.postcode} ${item.city} ${item.street}`;
            });
          } else {
            this.billingAddresses = [];
          }
          if (value.shipping && value.shipping.length > 0) {
            this.shippingAddresses = value.shipping;
            this.shippingAddresses.forEach((item) => {
              let county = item.county ? item.county + ", " : "";
              item.name = `${item.country}, ${county} ${item.postcode} ${item.city} ${item.street}`;
            });
          } else {
            this.shippingAddresses = [];
          }
        } else {
          this.shippingAddresses = [];
          this.billingAddresses = [];
        }
      },
      deep: true,
      immediate: true,
    },

    "formModel.sales_point_id": {
      handler: function (val) {
        if (this.formModel) {
          if (val) {
            this.formModel.salesPoint = Object.assign(
              {},
              this.getSalespointById(val)
            );
          } else {
            this.formModel.salesPoint == null;
          }

          if (this.formModel.sales_point_id) {
            this.owners = this.userCollection.filter((item) => {
              return (
                item.user_groups.filter((i) => {
                  return (
                    this.formModel.salesPoint &&
                    this.formModel.salesPoint.userGroup &&
                    i.id == this.formModel.salesPoint.userGroup.id
                  );
                }).length > 0
              );
            });
          }

          if (!this.permissionCan("assign.person")) {
            this.owners = this.userCollection.filter((item) => {
              return item.id == this.currentUser.id;
            });
          }
        }
      },
      deep: true,
      immediate: true,
    },
    /*
    "formModel.price_list_id": {
      handler: function(val) {
        alert("formModel.price_list_id");
        if (val == 1078) {
          let origiItems = this.formModel.items;
          console.log("origiItems", origiItems);

          this.$store.getters.getPriceListByID(val).then((i) => {
            this.selectedPriceList = Object.assign({}, i);
            if (this.selectedPriceList) {
              let appLocale = i18nService.getActiveLanguage();
              this.salesOrderItemCollection = this.selectedPriceList.items
                .filter((it) => {
                  return it.sale == 1 && it.status == 1;
                })
                .map((item) => {
                  if (item.translations.length == 0) {
                    item.name = "Name not defined";
                    return item;
                  }

                  if (item.translations[appLocale]) {
                    item.name = item.translations[appLocale].name;
                  } else {
                    item.name =
                      item.translations[Object.keys(item.translations)[0]].name;
                  }
                  item.ReferenceName = item.reference + " | " + item.name;
                  return item;
                });
              this.formModel.items = origiItems;
            } else {
              // this.selectedPriceList = { currency_id: 1 };
              this.selectedPriceList = { currency_id: null };
              this.salesOrderItemCollection = [];
            }
          });
        } else {
          // this.selectedPriceList = { currency_id: 1 };
          // this.selectedPriceList = { currency_id: null };
          // this.formModel.items = [];
        }
      },
      deep: true,
      immediate: true,
    },
*/
    // "formModel.items": {
    //   handler: function(val) {
    //     console.log("val", val);
    //     if (!val) {
    //       this.calculateCalculatedItems([]);
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },

    "formModel.customer": {
      handler: function (val) {
        if (val) {
          this.calculateCustomerCompany(val);
        }
      },
      deep: true,
      immediate: true,
    },

    // "itemInputData.item": {
    //   handler: function (val) {
    //     if (
    //       val &&
    //       // val.price_lists &&
    //       this.itemInputData &&
    //       this.selectedPriceList
    //     ) {
    //       if (!val.price_lists) {
    //         this.itemInputData.price_net = this.getFormattedPrice(
    //           val.price_net
    //         );
    //         this.itemInputData.vat_name = val.vat_name;
    //         this.itemInputData.vat_value = val.vat_value;
    //       }
    //       this.calculatePriceGross();
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  methods: {
    ...mapActions([
      "fetchContactList",
      // "fetchCompany",
      "fetchCompanyType",
      "fetchIndustry",
      "fetchQualification",
      "fetchCompanyCategory",
      "fetchSupplierType",
      "fetchCountry",
    ]),

    handleChangeSelectedPriceList(val) {
      let formModel = Object.assign({}, this.formModel);

      this.$store.getters.getPriceListByID(val).then((i) => {
        this.selectedPriceList = Object.assign({}, i);
        if (this.selectedPriceList) {
          let appLocale = i18nService.getActiveLanguage();
          this.salesOrderItemCollection = this.selectedPriceList.items
            .filter((it) => {
              return it.sale == 1 && it.status == 1;
            })
            .map((item) => {
              if (item.translations.length == 0) {
                item.name = "Name not defined";
                return item;
              }

              if (item.translations[appLocale]) {
                item.name = item.translations[appLocale].name;
              } else {
                item.name =
                  item.translations[Object.keys(item.translations)[0]].name;
              }
              item.ReferenceName = item.reference + " | " + item.name;
              return item;
            });
          this.formModel = Object.assign({}, formModel);
        } else {
          this.selectedPriceList = { currency_id: null };
          this.salesOrderItemCollection = [];
        }
      });
    },

    calculateCalculatedItems(model) {
      // console.log(this.selectedPriceList, this.itemCollection);
      this.showSortedItems = false;
      this.calculatedItems = model.map((item) => {
        // item.item = this.itemCollection.find((ic) => ic.id == item.id);
        item.item = this.itemCollection.find((ic) => ic.id == item.item_id);

        item.item.item_id = item.item_id;
        // if (this.formModel.zero_tax) {
        //   item.netValue = this.getFormattedPrice(
        //     1 * item.num_of_pcs * 1 * item.price_net
        //   );
        //   item.tax = 0;
        //   item.grossValue = this.getFormattedPrice(1 * item.netValue);
        // } else {
        //   item.netValue = this.getFormattedPrice(
        //     1 * item.num_of_pcs * 1 * item.price_net
        //   );
        //   item.tax = this.getFormattedPrice(
        //     (1 * item.num_of_pcs * (1 * item.vat_value * 1 * item.price_net)) /
        //       100
        //   );
        //   item.grossValue = this.getFormattedPrice(
        //     1 * item.tax + 1 * item.netValue
        //   );
        // }

        // console.log(this.formModel.reverse_tax, item, item.item);
        if (this.formModel.zero_tax) {
          item.netValue = this.getFormattedPrice(
            1 * item.num_of_pcs * 1 * item.price_net
          );
          item.tax = 0;
          item.grossValue = this.getFormattedPrice(1 * item.netValue);
        } else if (
          this.formModel.reverse_tax &&
          item.item &&
          item.item.service == 0
        ) {
          item.netValue = this.getFormattedPrice(
            1 * item.num_of_pcs * 1 * item.price_net
          );
          item.tax = 0;
          item.grossValue = this.getFormattedPrice(1 * item.netValue);
        } else {
          item.netValue = this.getFormattedPrice(
            1 * item.num_of_pcs * 1 * item.price_net
          );
          item.tax = this.getFormattedPrice(
            (1 * item.num_of_pcs * (1 * item.vat_value * 1 * item.price_net)) /
              100
          );
          item.grossValue = this.getFormattedPrice(
            1 * item.tax + 1 * item.netValue
          );
        }

        if (item.item) {
          item.name = item.item.name;
          item.quantityUnit = this.getQuantityUnitById(
            item.item.quantity_unit_id
          );
        }
        return item;
      });

      this.showSortedItems = true;
    },

    loadFormData(value) {
      this.$nextTick(() => {
        ApiService.get(ENDPOINT + value)
          .then(({ data }) => {
            if (!value) {
              this.elements = initialElements();
            } else {
              this.elements = Object.assign({}, data.elements);
            }
            data.salesPoint = Object.assign(
              {},
              this.salesPointCollection.find(
                (item) => item.id == data.sales_point_id
              )
            );
            // data.addresses = JSON.parse(data.addresses);

            if (data.contact_id && data.company_id) {
              data.customer = this.contactListCollection.find((item) => {
                return (
                  data.contact_id == item.contact_id &&
                  data.company_id == item.companyId
                );
              });

              let self = this;
              setTimeout(
                () => {
                  self.handleChangeCustomer();
                },
                500,
                self
              );
            }

            if (!data.contact_id && data.company_id) {
              data.customer = this.contactListCollection.find((item) => {
                return data.company_id == item.companyId && !item.contact_id;
              });
              // if (!data.customer) {
              //   data.customer = this.contactListCollection.find((item) => {
              //     return data.company_id == item.companyId;
              //   });
              // }
              if (!data.customer) {
                ApiService.get("contact/company/" + data.company_id).then(
                  (response) => {
                    let companyData = response.data;

                    if (companyData.company_category) {
                      companyData.company_category_id =
                        companyData.company_category.id;
                    }
                    if (companyData.industry) {
                      companyData.industry_id = companyData.industry.id;
                    }
                    if (companyData.types[0]) {
                      companyData.company_type_id = companyData.types[0];
                    }

                    if (companyData.qualification) {
                      companyData.qualification_id =
                        companyData.qualification.id;
                    }

                    // console.log("companyData", companyData);
                    let customer = {
                      model: "Company",
                      id: data.company_id,
                      contact_id: null,
                      name: companyData.name,
                      email: null,
                      company: null,
                      companyId: data.company_id,
                      companyAddresses: companyData.addresses,
                      contact_data: [],
                      companyTypeName: "Vásárló",
                      vat_number: companyData.vat_number,
                      companyType: {
                        id: 1,
                        translations: {
                          hu: {
                            name: "Vásárló",
                          },
                          en: {
                            name: "Customer",
                          },
                        },
                      },
                      companyName: companyData.name,
                    };
                    this.contactListCollection.push(customer);

                    this.customerCompany = {
                      id: data.company_id,
                      vat_number: companyData.vat_number,
                    };

                    // data.customer = customer;
                    // this.formModel.customer = customer;
                    // this.formModel.customer = this.contactListCollection.find(
                    //   (item) => {
                    //     return (
                    //       data.company_id == item.companyId && !item.contact_id
                    //     );
                    //   }
                    // );

                    // data.customer = customer;
                    let self = this;
                    setTimeout(
                      () => {
                        self.formModel.customer = customer;
                        self.handleChangeCustomer();
                      },
                      500,
                      self,
                      customer
                    );
                    // console.log("data.customer", data.customer);
                  }
                );
              } else {
                this.formModel.customer = data.customer;
                this.handleChangeCustomer();
              }
            }

            data.items = data.items.map((i) => {
              i.name = this.$helpers.getTranslated(i.translations).name;
              i.quantityUnit = this.$helpers.getTranslated(
                i.quantity_unit.translations
              ).name;

              // i.item_id = i.id;
              // i.item = this.itemCollection.find((it) => {
              //   return i.item_id == it.id;
              // });

              return i;
            });
            // console.log(data.items, this.itemCollection);
            // debugger;
            this.formModel = Object.assign({}, data);

            if (!this.formModel.special_price_list_id)
              this.formModel.special_price_list_id = 0;

            if (
              this.elements.scheduledDateSelector &&
              !this.formModel.scheduled_date
            ) {
              this.formModel.scheduled_date = this.formModel.delivery_date;
            }

            this.modalData.dialog = true;
            this.selectedPriceList = {
              // currency_id: 1,
              currency_id: null,
            };
            this.newVersion = false;
            if (!this.formModel.locale) {
              this.formModel.locale = i18nService.getActiveLanguage();
            }
            this.formModel.prev_status = this.formModel.status;

            // this.elements = initialElements();
            // if (value) {
            //   this.elements = Object.assign({}, this.formModel.elements);
            // }
            this.calculateCalculatedItems(this.formModel.items);

            this.handleChangeSelectedPriceList(this.formModel.price_list_id);
            this.setTranslatedAttributes();
            this.resetErrorMessages();
            if (this.$refs.formItemData)
              this.$refs.formItemData.resetValidation();
            this.loader = false;
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      });
    },

    // ...mapActions([FETCH_CURRENCY]),
    handleCloseModalForm() {
      this.itemInputData = Object.assign({}, initialItemInputData);
      this.customerCompany = { vat_number: "" };
      this.elements = initialElements();
      this.resetErrorMessages();
      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);
      this.formModel.old_status = model.status;
      this.$refs.form.validate();
      if (
        this.formModel &&
        this.formModel.payment_method &&
        this.formModel.customer &&
        !this.formModel.customer.email &&
        this.formModel.payment_method == "3"
      ) {
        Swal.fire({
          title: `<strong>ALERT</strong>`,
          icon: "error",
          width: "600px",
          html: `<b>Contact E-mail is an obligatory to choose Credit card payment method</b> `,
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#e33354",
          infoColor: "#e33354",
        });
        return false;
      }

      if (
        this.formModel &&
        this.formModel.payment_method &&
        this.selectedPriceList &&
        this.formModel.payment_method == "3" &&
        this.selectedPriceList.currency_id != "HUF"
      ) {
        Swal.fire({
          title: `<strong>ALERT</strong>`,
          icon: "error",
          width: "600px",
          html: `<b>Credit card payment method can be selected only if the currency is HUF</b>`,
          showCloseButton: true,
          focusConfirm: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#e33354",
          infoColor: "#e33354",
        });
        return false;
      }

      if (this.formValid) {
        this.loader = true;
        this.resetErrorMessages();
        if (this.formModel.prev_status != 0 && model.customer) {
          model.contact_id = model.customer.contact_id;
          model.company_id = model.customer.companyId;
        }

        if (model.id && !this.newVersion) {
          ApiService.put(ENDPOINT + model.id, model)
            .then((data) => {
              this.snackbar = true;
              this.elements = Object.assign({}, data.data.elements);

              // this.$emit("saveModalForm", Object.assign({}, this.formModel));
              this.loader = false;

              let formModelid = this.formModel.id;
              this.formModel = initialFormData();
              // this.loadFormData(this.formModel.id);
              this.loadFormData(formModelid);
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
              this.loader = false;
              if (this.formModel.prev_status)
                this.formModel.status = this.formModel.prev_status;
            });
        } else {
          //create model
          let newVersionUrl = "";
          if (this.newVersion) newVersionUrl = model.id + "/new";
          ApiService.post(ENDPOINT + newVersionUrl, model)
            .then(({ data }) => {
              this.snackbar = true;
              // this.elements = Object.assign({}, data.data.elements);
              // if (this.newVersion) {

              // this.$emit("closeModalForm");
              // this.$emit("saveModalForm", Object.assign({}, data));
              this.loadFormData(data.id);
              this.newversion = false;
              this.loader = false;
              // }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
                if (this.formModel.prev_status)
                  this.formModel.status = this.formModel.prev_status;
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              this.loader = false;
            });
        }
      } else {
        if (this.formModel.prev_status)
          this.formModel.status = this.formModel.prev_status;
      }
    },

    handleDeleteItem(item) {
      this.itemTodelete = item;
      this.dialogDeleteItem = true;
    },

    deleteItemConfirm() {
      this.formModel.items.splice(
        this.formModel.items.indexOf(this.itemTodelete),
        1
      );
      this.calculateCalculatedItems(this.formModel.items);
      this.closeDeleteItem();
    },

    closeDeleteItem() {
      this.itemTodelete = {};
      this.dialogDeleteItem = false;
    },

    setTranslatedAttributes(newItem = false) {
      if (newItem) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },

    handleNew() {
      this.formModel = initialFormData();
      // this.formModel.items = [];
      this.selectedPriceList = {};
      this.itemInputData = Object.assign({}, initialItemInputData);
      this.calculatedItems = null;
      if (this.$refs.formItemData) this.$refs.formItemData.resetValidation();
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.resetErrorMessages();
      this.setTranslatedAttributes(true);
      this.$emit("handleNew");
    },

    resetErrorMessages() {
      let errorData = initialFormData();
      this.languages.forEach((language) => {
        TRANSLATED_ATRIBUTES.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "sales.salesOrder." + action
      );
    },

    addDays(days) {
      let newDate = new Date().addDays(days);
      this.formModel.expiration_date =
        newDate.getFullYear() +
        "-" +
        1 * (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate();
    },

    getQuantityUnitById(id) {
      let qUnit = this.quantityUnitCollection.find((item) => item.id == id);
      if (!qUnit) return "";
      return qUnit.name;
    },

    getSalespointById(salesPointId) {
      return this.salesPointCollection.find((item) => item.id == salesPointId);
    },

    calculatePriceGross() {
      if (this.itemInputData.price_net) {
        // this.itemInputData.netValue = this.getFormattedPrice(
        //   this.itemInputData.num_of_pcs * this.itemInputData.price_net
        // );

        this.$set(
          this.itemInputData,
          "netValue",
          this.getFormattedPrice(
            this.itemInputData.num_of_pcs * this.itemInputData.price_net
          )
        );

        // this.itemInputData.tax = this.getFormattedPrice(
        //   (this.itemInputData.netValue / 100) * this.itemInputData.vat_value
        // );

        this.$set(
          this.itemInputData,
          "tax",
          this.getFormattedPrice(
            (this.itemInputData.netValue / 100) * this.itemInputData.vat_value
          )
        );

        // this.itemInputData.grossValue = this.getFormattedPrice(
        //   this.itemInputData.netValue + this.itemInputData.tax
        // );

        this.$set(
          this.itemInputData,
          "grossValue",
          this.getFormattedPrice(
            this.itemInputData.netValue + this.itemInputData.tax
          )
        );

        this.handleCalculateBrutto();
      }
    },

    handleAddItemData() {
      this.$refs.formItemData.validate();
      if (this.formItemDataValid) {
        let itemInputData = Object.assign({}, this.itemInputData);

        itemInputData.id = itemInputData.id ?? itemInputData.item.item_id;

        itemInputData.unit_weight =
          itemInputData.unit_weight ?? itemInputData.item.unit_weight;

        itemInputData.item_id =
          itemInputData.item_id ?? itemInputData.item.item_id;

        itemInputData.price_list_item_id =
          itemInputData.price_list_item_id ??
          itemInputData.item.price_list_item_id;
        itemInputData.name = itemInputData.name ?? itemInputData.item.name;
        itemInputData.reference =
          itemInputData.reference ?? itemInputData.item.reference;
        itemInputData.price_net = this.getFormattedPrice(
          itemInputData.price_net
        );
        itemInputData.netValue = this.getFormattedPrice(itemInputData.netValue);
        itemInputData.tax = this.getFormattedPrice(itemInputData.tax);

        itemInputData.grossValue = this.getFormattedPrice(
          itemInputData.grossValue
        );

        // itemInputData;

        this.formModel.items.push(Object.assign({}, itemInputData));
        this.itemInputData = Object.assign({}, this.initialItemInputData);
        this.$refs.formItemData.resetValidation();
        this.isEditedItemData = false;
        this.cancelItemData = null;
        this.calculateCalculatedItems(this.formModel.items);
      }
    },

    handleCancelItemData() {
      this.formModel.items.unshift(Object.assign({}, this.cancelItemData));
      this.itemInputData = Object.assign({}, this.initialItemInputData);
      this.$refs.formItemData.resetValidation();
      this.cancelItemData = null;
      this.isEditedItemData = false;
      this.calculateCalculatedItems(this.formModel.items);
    },

    handleEditItem(item) {
      if (!this.isEditedItemData) {
        this.$nextTick(() => {
          const scrollToElement = this.$refs.scrollTo;

          if (scrollToElement) {
            scrollToElement.scrollIntoView(true);
          }
        });
        let priceSelectedItemObject = this.itemCollection.find((it) => {
          return item.item_id == it.id;
        });
        item.item = priceSelectedItemObject;
        this.itemInputData = Object.assign({}, item);
        this.cancelItemData = Object.assign({}, item);
        this.formModel.items.splice(this.formModel.items.indexOf(item), 1);
        this.isEditedItemData = true;
        this.calculateCalculatedItems(this.formModel.items);
      }
    },

    handleCalculateBrutto() {
      if (this.itemInputData.price_net) {
        this.itemInputData.price_gross = this.getFormattedPrice(
          Number(this.itemInputData.price_net) *
            (Number(this.itemInputData.vat_value) / 100 + 1)
        );
      }
    },

    handleCalculateNetto() {
      if (this.itemInputData.price_gross) {
        // this.itemInputData.price_net = this.getFormattedPrice(
        //   Number(this.itemInputData.price_gross) /
        //     (Number(this.itemInputData.vat_value) / 100 + 1)
        // );

        this.$set(
          this.itemInputData,
          "price_net",
          this.getFormattedPrice(
            Number(this.itemInputData.price_gross) /
              (Number(this.itemInputData.vat_value) / 100 + 1)
          )
        );

        // this.itemInputData.netValue = this.getFormattedPrice(
        //   this.itemInputData.num_of_pcs * this.itemInputData.price_net
        // );

        this.$set(
          this.itemInputData,
          "netValue",
          this.getFormattedPrice(
            this.itemInputData.num_of_pcs * this.itemInputData.price_net
          )
        );

        // this.itemInputData.tax = this.getFormattedPrice(
        //   (this.itemInputData.netValue / 100) * this.itemInputData.vat_value
        // );

        this.$set(
          this.itemInputData,
          "tax",
          this.getFormattedPrice(
            (this.itemInputData.netValue / 100) * this.itemInputData.vat_value
          )
        );

        // this.itemInputData.grossValue = this.getFormattedPrice(
        //   this.itemInputData.netValue + this.itemInputData.tax
        // );

        this.$set(
          this.itemInputData,
          "grossValue",
          this.getFormattedPrice(
            this.itemInputData.netValue + this.itemInputData.tax
          )
        );
      }
      // this.itemInputData.netValue = this.getFormattedPrice(
      //   this.itemInputData.quantity * this.itemInputData.price_net
      // );
      // this.handleCalculateBrutto();
    },

    handleStatusSubmit(statusId) {
      // if (
      //   (statusId == 4 || statusId == 5) &&
      //   !this.customerCompany.vat_number &&
      //   order
      // ) {
      //   Swal.fire({
      //     title: "",
      //     text: "The Tax number field is required!",
      //     icon: "error",
      //   });
      // } else {
      //   this.formModel.old_status = this.formModel.status;
      //   this.formModel.prev_status = this.formModel.status;
      //   this.formModel.status = statusId;
      //   this.handleSaveModalForm();
      // }
      //console.log(order);
      this.formModel.old_status = this.formModel.status;
      this.formModel.prev_status = this.formModel.status;
      this.formModel.status = statusId;
      this.handleSaveModalForm();
    },

    handleClearDeliveryError() {
      this.messages["delivery_date"] = "";
      this.messages["delivery_days"] = "";
    },

    calculateStatus(status) {
      if (status > 7) return status - 2;
      return status;
    },

    handleLost(lost) {
      this.formModel.lost = lost;
      this.handleSaveModalForm();
    },

    handleDeleteSalesOrder() {
      this.$emit("handleDeleteSalesOrder", this.formModel);
    },

    handleNewVersion() {
      this.newVersion = true;
      this.formModel.status = 1;
      this.elements = initialElements();
      this.formModel.expiration_date = null;
    },

    printSalesOrder() {
      window.open(
        process.env.VUE_APP_PORTAL_URL +
          `print/so/?so=${this.formModel.hash}&print=1`
      );
    },

    printOffer() {
      window.open(
        process.env.VUE_APP_PORTAL_URL +
          `print/so/?so=${this.formModel.hash}&print=1&offer=1`
      );
    },

    getFormattedPrice(price) {
      let currency = this.selectedPriceList.currency_id;

      if (price == 0 || !price || isNaN(price)) return "";

      if (currency == "HUF") {
        return Math.round(price);
        // return parseInt(price);
      }

      return (1 * price).toFixed(2);
    },

    handleCloseSendEmailDialog() {
      this.sendEmailDialog = false;
      this.loadFormData(this.formModel.id);
    },

    handleCloseActivityModal() {
      this.activityDialog = false;
    },

    handleZeroTaxChange() {
      if (this.formModel.items) {
        this.calculateCalculatedItems(this.formModel.items);
      }
    },

    handleEditCompany(customer) {
      ApiService.get("contact/company/" + customer.companyId)
        .then(({ data }) => {
          this.companyModalData.editedIndex = customer.companyId;
          if (data.company_category) {
            data.company_category_id = data.company_category.id;
          }
          if (data.industry) {
            data.industry_id = data.industry.id;
          }
          if (data.types[0]) {
            data.company_type_id = data.types[0];
          }
          if (data.qualification) {
            data.qualification_id = data.qualification.id;
          }
          this.companyModalData.editedItem = Object.assign({}, data);
          this.companyModalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleNewCompany() {
      // this.fetchCompany();
      this.companyModalData.editedIndex = null;
      this.companyModalData.editedItem = Object.assign(
        {},
        initialCompanyFormData()
      );
      this.companyModalData.dialog = true;
    },

    handleCloseCompanyModalForm() {
      this.companyModalData.dialog = false;
      this.companyModalData.editedItem = Object.assign(
        {},
        initialCompanyFormData()
      );
    },

    async handleSaveCompanyModalForm(model = null) {
      // console.log("model", model);

      this.fetchContactList().then(() => {
        if (model) {
          if (!model.data) {
            // this.formModel.customer = Object.assign(
            //   {},
            //   this.contactListCollection.find((item) => {
            //     return item.companyId == model.id && item.model == "Company";
            //   })
            // );
            //
            // console.log(
            //   "this.contactListCollection",
            //   this.contactListCollection
            // );

            // let customer = Object.assign(
            //   {},
            //   this.contactListCollection.find((item) => {
            //     return item.companyId == model.id && item.model == "Company";
            //   })
            // );

            let customer = Object.assign(
              {},
              this.contactListCollection.find((item) => {
                return item.companyId == model && item.model == "Company";
              })
            );

            this.$set(this.formModel, "customer", customer);

            this.customerCompany = {
              id: model.id,
              vat_number: model.vat_number,
            };

            this.handleEditCompany({ companyId: model });
            // this.handleEditCompany(this.formModel.customer);
          } else {
            if (model.data && model.data.id) {
              let companyId = model.data.id;
              let contactId = null;
              if (model.data.contacts.length > 0) {
                contactId =
                  model.data.contacts[model.data.contacts.length - 1].id;
                companyId =
                  model.data.contacts[model.data.contacts.length - 1]
                    .company_id;
              }

              // console.log("contactId", contactId);
              // console.log("companyId", companyId);

              if (contactId && companyId) {
                // this.formModel.customer = this.contactListCollection.find(
                //   (item) => {
                //     return contactId == item.id && companyId == item.companyId;
                //   }
                // );

                let customerWithContact = this.contactListCollection.find(
                  (item) => {
                    return contactId == item.id && companyId == item.companyId;
                  }
                );

                this.$set(this.formModel, "customer", customerWithContact);
              }
              if (!contactId && companyId) {
                // this.formModel.customer = this.contactListCollection.find(
                //   (item) => {
                //     return companyId == item.companyId && !item.contact_id;
                //   }
                // );
                let customer = this.contactListCollection.find((item) => {
                  return companyId == item.companyId && !item.contact_id;
                });
                this.$set(this.formModel, "customer", customer);
                // this.handleEditCompany(model);
              }
            }
          }
        }
      });
      // this.handleCloseCompanyModalForm();
    },

    handleFetchContactList() {},

    setCompanyPermissions() {
      this.companyPermissions = JSON.parse(localStorage.permissions).filter(
        (item) =>
          item.name.indexOf("company") > -1 || item.name.indexOf("contact") > -1
      );
    },

    calculateCustomerCompany(val) {
      if (val.companyId) {
        this.customerCompany = {
          id: val.companyId,
          vat_number: val.vat_number,
        };
      } else {
        this.customerCompany = { vat_number: "" };
      }
    },

    editContactItem(id) {
      if (id) {
        this.modalDataContact.dialog = true;
        this.modalDataContact.editedId = id;
      }
    },

    handleNewContact() {
      this.modalDataContact.editedId = null;
      this.modalDataContact.dialog = true;
    },

    handleSaveContactModal(modalId = null) {
      this.fetchContactList().then(() => {
        let self = this;
        setTimeout(
          () => {
            if (modalId) {
              // this.formModel.customer = Object.assign(
              self.formModel.customer = Object.assign(
                {},
                self.contactListCollection.find((item) => {
                  return item.id == modalId && item.model == "Contact";
                })
              );

              self.handleChangeCustomer();
            }
          },
          500,
          self,
          modalId,
          this.contactListCollection
        );
      });
    },

    handleCloseContactModal() {
      this.modalDataContact.editedId = null;
      this.modalDataContact.dialog = false;
    },

    handleOpenSOEntitySelectDialog(item) {
      if (this.elements.entitySelector) {
        this.salesOrderItemId = item.sales_order_item_id;
      }
    },

    handleCloseSOEntityDialog(data = null) {
      if (data) {
        this.formModel.status = data.status;
      }
      this.salesOrderItemId = null;
      // debugger;
    },

    handleOpenDetailedStatus() {
      this.SODetailedStatusdialog = true;
    },

    handleCloseSODetailedDialog() {
      this.SODetailedStatusdialog = false;
    },

    handleQuantityChange() {
      if (this.itemInputData.item && this.itemInputData.quantity) {
        let priceListItem = this.filteredSalesOrderItemCollection.find(
          (item) => {
            return (
              item.quantity == this.itemInputData.quantity &&
              item.item_id == this.itemInputData.item.item_id
            );
          }
        );

        if (priceListItem) {
          this.itemInputData.price_net = this.getFormattedPrice(
            priceListItem.price_net
          );

          this.itemInputData.vat_name = priceListItem.vat_name;
          this.itemInputData.vat_value = priceListItem.vat_value;

          this.itemInputData.item = Object.assign({}, priceListItem);

          this.calculatePriceGross();
        }

        let selectedSpecialPriceList = null;
        if (
          this.formModel.special_price_list_id &&
          this.formModel.special_price_list_id > 0
        ) {
          this.$store.getters
            .getPriceListByID(this.formModel.special_price_list_id)
            .then((i) => {
              selectedSpecialPriceList = Object.assign({}, i);
              let specialPriceListItem = null;

              if (selectedSpecialPriceList) {
                specialPriceListItem = selectedSpecialPriceList.items.filter(
                  (specialPriceListItem) =>
                    specialPriceListItem.status &&
                    specialPriceListItem.item_id == priceListItem.item_id &&
                    specialPriceListItem.quantity == priceListItem.quantity
                );

                // console.log(
                //   "this.formModel.special_price_list_id",
                //   this.formModel.special_price_list_id
                // );
                // console.log("selectedSpecialPriceList", selectedSpecialPriceList);
                // console.log("specialPriceListItem", specialPriceListItem);
              }

              if (specialPriceListItem && specialPriceListItem.length > 0) {
                let netPriceTranslated = "Original net unit price: ";
                if (this.formModel.locale == "hu") {
                  netPriceTranslated = "Eredeti nettó egységár: ";
                }
                if (this.formModel.locale == "ro") {
                  netPriceTranslated = "Pret unitar net initial: ";
                }
                this.itemInputData.public_notes =
                  netPriceTranslated + priceListItem.price_net;

                this.itemInputData.price_net = this.getFormattedPrice(
                  specialPriceListItem[0].price_net
                );

                this.calculatePriceGross();
              }
            });
        }

        // let specialPriceListItem = this.filteredSalesOrderItemCollection.find(
        //   (item) => {
        //     return (
        //       item.quantity == this.itemInputData.quantity &&
        //       item.item_id == this.itemInputData.item.item_id
        //     );
        //   }
        // );
      }
    },

    saveOrder(event) {
      const movedItem = this.formModel.items.splice(event.oldIndex, 1)[0];
      this.formModel.items.splice(event.newIndex, 0, movedItem);
    },

    handleDeleteCompany(model) {
      this.company2Delete = model;
      this.dialogDeleteCompany = true;
    },

    deleteCompanyConfirm() {
      ApiService.delete("contact/company/" + this.company2Delete.id)
        .then(() => {
          this.companyModalData = {
            dialog: false,
            editedIndex: null,
            editedItem: Object.assign({}, initialCompanyFormData()),
          };
          this.formModel.customer = null;
          this.fetchContactList();
          this.fetchCompany();
          this.fetchCompanyType();
          this.fetchIndustry();
          this.fetchQualification();
          this.fetchCompanyCategory();
          this.fetchSupplierType();
          this.fetchCountry();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
      this.fetchContactList();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogDeleteCompany = false;
      this.contact2Delete = {};
      this.company2Delete = {};
      this.$nextTick(() => {
        this.editedIndex = null;
      });
    },

    handleChangeItemInputDataItem(item) {
      this.itemInputData = Object.assign({}, initialItemInputData);
      this.messages["items"] = "";
      this.itemInputData.item = item;

      // this.itemInputData.item.unit_weight = item;
    },

    handleShowACactivityDialog() {
      this.ACDialogData.show = true;
      this.ACDialogData.contactID = this.formModel.contact_id;
    },

    handleCloseACactivityDialog() {
      this.ACDialogData.show = false;
      this.ACDialogData.contactID = null;
    },

    handleOpenAC() {
      this.loader = true;
      ApiService.get(`contact/contact/${this.formModel.contact_id}/acId`).then(
        ({ data }) => {
          if (!data) {
            Swal.fire({
              title: "AC",
              text: "AC data not found!",
              icon: "error",
            });
          } else {
            window.open(
              `https://kismetal.activehosted.com/app/contacts/${data}`
            );
          }
          this.loader = false;
        }
      );
    },

    handleChangeCustomer() {
      if (this.formModel.status != 11) {
        if (this.formModel.customer.google_rating > 0) {
          this.formModel.rating_notif = 0;
          // console.log("|ta serhis.formModel.customer.google_rating > 0");
        }/* else {
          this.formModel.rating_notif = null;
        }*/
      }
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          filter: ".fixed",
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };

        let elements = el.getElementsByTagName("tbody")[0];
        Sortable.create(elements, options);
      },
    },
  },

  mounted() {
    this.fetchCompanyType();
    this.fetchIndustry();
    this.fetchQualification();
    this.fetchCompanyCategory();
    this.fetchSupplierType();
    this.fetchCountry();
    this.setCompanyPermissions();
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

.v-text-field__details {
  color: red !important;
}
</style>
